import React, { Component } from 'react';

import Loader from './components/loader';
import Results from './components/results';

import Login from './components/login';
//import GetSharedKey from './components/getsharedkey';
import Button from './components/button';
import Select from './components/select';
import GoogleTagManager from './components/google-tag-manager';

import * as Utils from './utils/utils'; 
import * as Filter from './utils/filter'; 
import * as NLForm from './nlform'; 
import * as Translations from './utils/translations';
import JsonFind from 'json-find';

let wineTypeOptions = Filter.GetWineTypes().map(_type => ({
  value: _type.id.toString(), label: _type.name
}));

let ageOptions = Filter.GetAgeOptions().map(_type => ({
    value: _type.id.toString(), label: _type.name
}));

let translations = Translations.GetAll();
const errorMessages = JsonFind(translations.errors);

let emptyFilter = { name: '', minPrice: '0', maxPrice: '9999', type: '', grape: '', origin: '', age: '' };

class Home extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            showLoader: false,
            appInited: false,
            loadingMessage: translations.init_app,
            showResults: false,
            allWines:[],
            wines:[],
            countryOptions:[],
            cellarOptions:[],
            grapeOptions:[],
            wineTypeFilter: "",
            grapeTypeFilter: "",
            originFilter: "",
            minPriceFilter:0,
            maxPriceFilter:500,
            login:{handle:'',password:''},
            loginMessage:'',
            showLogin: false,
            currency: translations.currency,
            showSharedKey: false,
            sharedKey:''
        };
        this.buttonClicked = this.buttonClicked.bind(this);
        this.login = this.login.bind(this);
        //this.getsharedkey = this.getsharedkey.bind(this);
    }

    async componentDidMount() {
        const credentials = Utils.GetCredentials();
        if (typeof credentials !=='undefined') {
            this.login(credentials);
        } else {
            this.setState({ showLogin: true});
        }
    }

    initSearch() {
        window.location.hash = 'search';
        this.setState({ showLoader: true, showResults: false, wines: [], loadingMessage: translations.search_wines });
    }

    initForms() {
        const that = this;
        new NLForm.NLForm(document.getElementById("nl-form"));
        let userAgent = navigator.userAgent;
        const isMobileDevice = userAgent.indexOf('iPhone') > 0 || userAgent.indexOf('Android') > 0 || userAgent.indexOf('Windows Phone') > 0;
        if (isMobileDevice) {
            window.addEventListener('orientationchange', function (e) {
                that.setOrientation(window.orientation);
            }, false);
        }
    }
  
    setOrientation = (orientationValue) => {
        const that = this;
        if (orientationValue !== 0)
            that.setState( { showLoader: true, loadingMessage: translations.errors.app_landscape_unsupported } );
        else
            that.setState( { showLoader: false} );
    }

    delayResults(wines) {
        const that = this;
        setTimeout(function(){
            that.presentResults(wines);
        }, 2500);
    }

    presentResults(result){
        const that = this;
        if (!result.length)
        {
            that.setState( { loadingMessage: translations.search_no_results } );
            setTimeout(function(){
            that.setState( { showLoader: false} );
            }, 3000);
            return;
        }
        that.setState( { loadingMessage: translations.search_present_results } );
        that.setState( { showResults: true, wines: result } );
        //Utils.WinesToImpressions(result, translations.currency);
        window.location.hash = 'results';
        setTimeout(function(){
            that.setState( { showLoader: false } );
        }, 1000);
    }

    login(credentials) {
        this.setState( { login: credentials } );
        this.getWines(credentials);
    }
  
    buttonClicked(buttonName) {
        const { allWines } = this.state;
        this.initSearch();
        let filterObj = Object.assign({}, emptyFilter);

        //Utils.DataLayerPush({ 'event': 'button-click-' + buttonName });
        switch (buttonName) {
            case 'search': 
            case 'i-feel-lucky': {
            filterObj.type = document.getElementById("wine-type").value;
            filterObj.grape = document.getElementById("grape-variety").value;
            filterObj.origin = document.getElementById("origin").value;
            filterObj.location = document.getElementById("cellar").value;
            filterObj.minPrice = document.getElementById("price-min").value;
            filterObj.maxPrice = document.getElementById("price-max").value;
            filterObj.age = document.getElementById("age").value;
            let result = Filter.GetByFilter(allWines, filterObj);
            if (buttonName === 'i-feel-lucky') {
                result = Filter.SelectOne(result);
            }
            this.delayResults(result);
            break;
            }
            case 'barolo':
            case 'amarone':
            case 'champagne' : {
            filterObj.name = buttonName;
            filterObj.location = document.getElementById("cellar").value;
            this.delayResults(Filter.GetByFilter(allWines, filterObj));
            break;
            }
            case 'surprise-me' : {
            this.delayResults(Filter.GetRandomWine(allWines));
            break;
            }
            case 'show-favorites' : {
            this.delayResults(Filter.GetFavorites(allWines));
            break;
            }
            default: {
                this.delayResults([]);
                break;
            }
        }
    }

    async getWines(credentials) {
        this.setState({ showLoader: true, showLogin: false});
        await Utils.GetWines(credentials).then(response => {
            try {
                const wines = response.data;
                
                if (wines.length) {
                    Utils.SetStorage("credentials_cacheKey",credentials, 7);
                    const countries = Utils.GetDistinctCountryList(wines);
                    const grapeVarieties = Utils.GetDistinctGrapeList(wines);
                    const cellars = Utils.GetDistinctLocationList(wines);
                    const currency = Utils.GetDefaultCurrency(wines, translations.currency);
                    if (currency === 'EUR' || currency === 'USD' || currency === 'AUD' || currency === 'GBP') {
                        this.setState({ maxPriceFilter: 50 });
                    }
                    this.setState({ allWines: wines, countryOptions: countries, cellarOptions: cellars, currency: currency, grapeOptions: grapeVarieties, loadingMessage: translations.init_app_done});
                    this.initForms(); 
                    this.setState({ showLoader: false, appInited: true });
                }
                else {
                    this.setState({ loadingMessage: translations.errors.no_wines });
                }
            } catch (error) {
                console.log(error);
                this.setState( { loadingMessage: translations.errors.init_error } );
            }
        }).catch(error => {
            console.log(error.response);
            const errorMsg = (error.response && error.response.data && error.response.data.Message) ? errorMessages.checkKey(error.response.data.Message) : translations.errors.init_error;
            this.setState({ loginMessage: errorMsg, showLogin: true, showLoader: false });
        });
    }

    render() {
        const { wines, countryOptions, grapeOptions, login, cellarOptions } = this.state;

        return (
            <div>
            <GoogleTagManager gtmId='GTM-TR6538P' />
            <Login visible={this.state.showLogin} credentials={login} doLogin={this.login} message={this.state.loginMessage}/>
            <header className="container first-slide">
                {/* <Santa visible={false} /> */}
                <div className="main clearfix">
                    <div id="nl-form" className="nl-form">
                        {translations.form.id_like_a_wine_tip}, <br />
                        <Select defaultValue={translations.wine_types.any_type} id="wine-type" options={wineTypeOptions} />
                        <br /> {translations.form.on} 
                        <Select defaultValue={translations.form.any_grape} id="grape-variety" options={grapeOptions} /> 
                        <br />{translations.form.from} 
                        <Select defaultValue={translations.form.any_origin} id="origin" options={countryOptions} />
                        {translations.form.in} 
                        <Select defaultValue={translations.form.any_cellar} id="cellar" options={cellarOptions} />
                        <br />
                        <Select defaultValue={translations.age_types.any_age} id="age" options={ageOptions} />
                        <br />{translations.form.cost_span} <br />
                        <input type="number" min="0" max="9999" placeholder="amount" defaultValue="0" id="price-min" /> 
                        {translations.form.and}  
                        <input type="number" min="100" max="9999" placeholder="amount" defaultValue="500" id="price-max" />
                        {translations.currency} 
                        <div>
                            <Button id="button-search" text={translations.form.button_search} defaultValue="search" clickEvent={this.buttonClicked}/>
                            <Button id="button-search-luck" text="I feel lucky" defaultValue="i-feel-lucky" clickEvent={this.buttonClicked} />
                        </div>
                        <div>
                            <small className="divider"><i> ~ {translations.form.or} ~ </i></small>
                            <Button id="button-barolo" text="Barolo!" defaultValue="barolo" clickEvent={this.buttonClicked}/>
                                {/*<Button id="button-champagne" text="Champagne" defaultValue="champagne" clickEvent={this.buttonClicked}/> */}
                            <Button id="button-surprise" text={translations.form.button_surprise} defaultValue="surprise-me" clickEvent={this.buttonClicked} />
                            <Button id="button-favorites" text={translations.form.button_favorites} defaultValue="show-favorites" clickEvent={this.buttonClicked} />
                        </div>
                        <div className="nl-overlay"/>
                    </div>
                </div>
            </header>
            <Results showResults={this.state.showResults} result={wines} />
            <Loader visible={this.state.showLoader} init={this.state.appInited} message={this.state.loadingMessage}/>
            </div>
        );
    }
}

export default Home;