import React, { Component } from 'react';

class Points extends Component {
    render() {
        return (
            <span> {this.props.type}{this.props.pts} </span>
        );
    }
}

export default Points;