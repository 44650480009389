import * as Favorites from './favorites'; 
import * as Utils from './utils'; 
import * as Translations from '../utils/translations';

const translations = Translations.GetAll();

export function GetAgeOptions() {
    return [
        { id: 1, name: translations.age_types.up_to_ten_years },
        { id: 2, name: translations.age_types.ten_years_and_older },
    ];
}

export function GetWineTypes() {
    return [
        { id: 1, name: translations.wine_types.red },
        { id: 2, name: translations.wine_types.white },
        { id: 3, name: translations.wine_types.sparkling }
      ];
}

export function GetFavorites(wines) {
    const favs = Favorites.GetAll();
    if (typeof wines === "undefined" || wines.length === 0  || favs.length === 0 ) return [];
    let res = [];
    for (var i = 0;i < favs.length; i++) {
        var item = getWineById(wines, favs[i]);
        if (typeof item !== "undefined"){
            res.push( item );
        }
    }
    return res.sort(SortBy('Name', false, function (a) { return a.toUpperCase(); }));
}

export function GetByFilter(wines, filter) {
    if (!wines.length) return [];
    let items = FilterByWineType(wines, filter.type);
    items = FilterByGrape(items, filter.grape);
    items = FilterByName(items, filter.name);
    items = FilterByOrigin(items, filter.origin);
    items = FilterByLocation(items, filter.location);
    items = FilterByAge(items, filter.age);
    items = FilterByPrice(items, filter.minPrice, filter.maxPrice);
    return items.sort(SortBy('Name', false, function (a) { return a.toUpperCase(); }));
}

export function SelectOne(wines) {
    const rnd = rndNmb(Object.keys(wines).length);
    return wines.filter(function (wine, i) {
        return i === rnd;
    });
}

export function GetRandomWine(wines) {
    const rndCountry = PickRandomWineCountry(wines);
    const filteredWines = wines.filter(function (wine) {
        return wine.Country === rndCountry;
    });
    const rnd = rndNmb(Object.keys(filteredWines).length);
    return filteredWines.filter(function (wine, i) {
        return i === rnd;
    });
}

var PickRandomWineCountry = function (wines) { 
    const countries = Utils.GetDistinctCountries(wines);
    return countries[Math.floor(Math.random() * countries.length)];
};

var FilterByPrice = function (list, minPrice, maxPrice) {
    if (Utils.TryParseInt(maxPrice) === null || Utils.TryParseInt(maxPrice) === null) return list;
    return list.filter(function (wine) {
        return wine.Price >= minPrice && wine.Price <= maxPrice;
    });
};

var FilterByWineType = function (list, wineType) {
    if (wineType === '') return list;
    return list.filter(function (wine) {
        return wine.WineType.toString() === wineType;
    });
};

var FilterByName = function (list, name) {
    if (name === '') return list;
    return list.filter(function (wine) {
        return wine.Name.toLowerCase().includes(name.toLowerCase());
    });
};

var FilterByGrape = function (list, grapeName) {
    if (grapeName === '') return list;
    return list.filter(function (wine) {
        return wine.GrapeVariety.toLowerCase().includes(grapeName.toLowerCase());
    });
};

var FilterByOrigin = function (list, origin) {
    if (origin === '') return list;
    return list.filter(function (wine) {
        return wine.Country.toLowerCase().includes(origin.toLowerCase());
    });
};

var FilterByLocation = function (list, location) {
    if (location === '') return list;
    return list.filter(function (wine) {
        return typeof wine.Location !== 'undefined' && 
            wine.Location.toLowerCase().includes(location.toLowerCase());
    });
};

var GetAge = function (year) { return (new Date().getFullYear() - year); }

var FilterByAge = function (list, ageOption) {
    if (ageOption === '') return list;
    switch (ageOption) {
        case "1": {
            return list.filter(function (wine) { return wine.Year === 1001 || GetAge(wine.Year) < 10; });
        }
        case "2": {
            return list.filter(function (wine) { return wine.Year !== 1001 && GetAge(wine.Year) >= 10; });
        }
        default: {
            return list.filter(function (wine) { return wine.Year >= 0; });
        }
    }
};

var getWineById = function(wines, wineId){
    return wines.filter(function (wine) {
        return wine.Id === wineId;
    })[0];
};

var SortBy = function (field, reverse, primer) {
    let key = primer ?
        function (x) { return primer(x[field]); } :
        function (x) { return x[field]; };
    reverse = !reverse ? 1 : -1;
    return function (a, b) {
        return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
    };
};

var rndNmb = function (max) {
    return Math.floor(Math.random() * max);
};