import React, { Component } from 'react';
import { Link } from "react-router-dom";


class Terms extends Component {
  render() {
    return (
      <div>
        <div className="container" id="terms">
            <div className="main">
                <h1>Terms and conditions</h1>

                <p>This app acts like a graphical user interface presenting data from CellarTracker(TM) user account.</p>
                <p>Use your CellarTracker handle and password to connect the app to your inventory. 
                    The credentials is stored locally on your client but never in the app nor its services.
                </p>
                <p>When logging in - the app forwards the credentials via a service to fetch the inventory of that CellarTracker account though a web request. 
                    The result/inventory that's returned is cached for one (1) hour for performance reasons; 
                    The cached data can not be accessed by any other users or administrators.
                </p>
                
                <ul>
                  <li>This app only reads data from CellarTracker, no data is written to CellarTracker. </li>
                  <li>Only use CellarTracker credentials which belong to you.</li>
                  <li>The app relies on web connection to CellarTracker. If the CellarTracker service is unavailable, so will this app be.</li>
                  <li>The app cannot grant that any data returned from CellarTracker is not incorrect, nor can it correct it.</li>
                </ul>

                <p>By logging in with your CellarTracker credentials you agree to the terms and conditions stated obove.</p>
                <div>
                    <button className="nl-button" type="button"><Link to="/">Go back</Link></button>
                </div>
            </div>
        </div>
      </div>
    );
  }
}

export default Terms;