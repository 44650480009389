import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from './home';
// import SharedList from './sharedlist';
import Terms from './terms';

class App extends Component {
  render() {
    return (
      <Router>
      <div>
        <Route exact path="/" component={ Home } />
        {/* <Route path={`/shared/:listid`} component={ SharedList } /> */}
        <Route path="/terms" component={ Terms } />
      </div>
    </Router>
    );
  }
}

export default App;