import React, { Component } from 'react';

export default class Select extends Component {
    
    render() {
        var options = this.props.options.map(choice => {
            return <option key={choice.value} value={choice.value}>{choice.label}</option>
        });

        return (
            <select id={this.props.id} defaultValue={this.props.defaultValue}>
                <option value="">{this.props.defaultValue}</option>
                {options}
            </select>
        );
    }
}