import React, { Component } from 'react';
import Star from './star';
import Points from './points';
import * as Favorites from '../utils/favorites';
import * as Translations from '../utils/translations';
let translations = Translations.GetAll();

class Wine extends Component {
    constructor(props) {
        super(props);
        this.starClicked = this.starClicked.bind(this);
        
        this.state = {
            favorite: false,
            favoriteClass : "",
            secretClass : ""
        };
    }
    async componentDidMount() {
        var isFavorite = Favorites.IsFavorite(this.props.wine.Id);
        this.setState( { favorite: isFavorite, favoriteClass : isFavorite ? " isFavourite" : "", secretClass : this.props.wine.Quantity < 0 ? "secret" : "" } );
    }
    starClicked(wineId) {
        if (this.state.favorite)
        {
            Favorites.Remove(wineId);
            this.setState( { favorite: false, favoriteClass : "" } );
        }
        else
        {
            Favorites.Add(wineId);
            this.setState( { favorite: true, favoriteClass : " isFavourite" } );
        }
    }
    render() {
        const { wine } = this.props; 
        const classes = `${this.state.favoriteClass} ${this.state.secretClass}`;
        const points = wine.Points;
        let CT_points;
        if (points>0) {
            CT_points = <Points pts={points} type="CT"/>;
        } 
        
        function ShowPending(count) {
            return (count < 1) ?  "" : ", " + count + " " + translations.results.pending
        }

        return (
            <tr key={wine.Id} title={wine.Quantity + translations.results.in_cellar + ShowPending(wine.Pending)} data-id={wine.Id} className={classes} >
                <td>
                    <p>
                        <Star wineId={wine.Id} isFavorite={wine.IsFavorite} raiseEvent={this.starClicked} />
                        <strong>{wine.Name} {wine.Vintage}</strong> 
                        <span className="mobile-show"> {translations.results.from} <em>{wine.Region}, {wine.Country}</em> 
                            {translations.results.made_of} {wine.GrapeVariety} {translations.results.and_costs} {wine.Price} {wine.Currency} {CT_points} {wine.Location} </span>
                        <span className="mobile-show in-stock">{wine.Quantity}</span>
                    </p>
                </td>
                <td className="mobile-hide">{wine.Region}, {wine.Country}</td>
                <td className="mobile-hide">{wine.GrapeVariety}</td>
                <td className="mobile-hide">{CT_points}</td>
                <td className="mobile-hide">{wine.Location}</td>
                <td className="mobile-hide">{wine.Price} {wine.Currency} <span className="show-large">({wine.Quantity} {translations.results.pcs}{ShowPending(wine.Pending)})</span></td>
            </tr>
        );
    }
}

export default Wine;
