/* global location */
/* eslint no-restricted-globals: ["off", "location"] */
import axios from 'axios';

const DEFAULTS = {
    StorageType: 'localStorage',
    StorageExpiration: 1,
    Host: process.env.REACT_APP_API_URL,
    Client: process.env.REACT_APP_SITE_URL,
    FuncKey: process.env.REACT_APP_FUNC_KEY
};
console.log(DEFAULTS) 

export function GetWines(credentials) {
    const _forceUpdate = GetParameterByName("forceUpdate") === "1";
    let postData = {
        showType: GetParameterByName("showType") || 0,
        includeLocation: true,
        forceUpdate: _forceUpdate
    };

    return axios({
        url: `${ DEFAULTS.Host }/GetWines`,
        timeout: 60000,
        method: 'post',
        data: { postData },
        dataType: 'json',
        contentType: 'application/json',
        headers: getHeaders(credentials)
    });
}

function getHeaders(credentials) {
    let headerData = {
        'Access-Control-Allow-Origin': DEFAULTS.Client,
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Methods': 'GET,POST',
        'X-Authorization-User': credentials.handle,
        'X-Authorization-Key': credentials.password,
        'x-functions-key': DEFAULTS.FuncKey
    };
    return headerData;
}

// export function CreateSharedKey(credentials) {
//     let postData = {
//         showType: 0,
//         forceUpdate: 0,
//         includeLocation: false 
//     };

//     return axios({
//         url: `${ DEFAULTS.Host }/api/GetMyWines/CreateSharedListKey`,
//         method: 'post',
//         data: {postData},
//         dataType: 'json',
//         contentType: 'application/json',
//         headers: {
//             'Access-Control-Allow-Origin': DEFAULTS.Client,
//             'Access-Control-Allow-Headers': '*',
//             'Access-Control-Allow-Methods': 'GET,POST',
//             'X-Authorization-User': credentials.handle,
//             'X-Authorization-Key': credentials.password
//         }
//     });
// }

// export function GetWinesByListId(listid) {
//     return axios({
//         url: `${ DEFAULTS.Host }/api/GetMyWines/GetSharedList/${ listid }`,
//         method: 'post',
//         data: {},
//         dataType: 'json',
//         contentType: 'application/json',
//         headers: {
//             'Access-Control-Allow-Origin': DEFAULTS.Client,
//             'Access-Control-Allow-Headers': '*',
//             'Access-Control-Allow-Methods': 'GET,POST'
//         }
//     });
// }

export function GetCredentials() {
    if (StorageAvailable()) {
        const cachedCredendials = GetStorage("credentials_cacheKey");
        if (cachedCredendials !== null) {
            const _credentials = JSON.parse(cachedCredendials);
            if (typeof _credentials.handle !=='undefined' && typeof _credentials.password !=='undefined') {
                return _credentials
            }
            return null
        } 
    }
}

export function GetDefaultCurrency(allWines, defaultCurrency) {
    if (!allWines.length) return defaultCurrency;
    return allWines[0].Currency;
}

export function GetDistinctCountryList(allWines) {
    const countries = GetDistinctCountries(allWines);
    const countryList = countries.sort().map(country => ( { value: country, label: country } ) );
    return countryList;
}

export function GetDistinctCountries(allWines) {
    const countryCacheKey = "countries_cacheKey";
    if (StorageAvailable()) {
      const cachedCountries = GetStorage(countryCacheKey);
      if (cachedCountries !== null) {
        const _countries = JSON.parse(cachedCountries);
        if (_countries.length) {
          return _countries;
        }
      } 
    }
    let countries = [];
    if (allWines.length) {
        for (let w = 0; w < allWines.length - 1; w++) {
            if (allWines[w].Quantity === 0) continue;
            let countryName = allWines[w].Country;
            if (countries.includes(countryName)) continue;
            countries.push(countryName);    
        }
    }
    if (countries.length) SetStorage(countryCacheKey, countries);
    return countries;
}

export function GetDistinctGrapeList(allWines) {
    const grapes = GetDistinctGrapeVarieties(allWines);
    const grapeList = grapes.sort().map(grape => ( { value: grape, label: grape } ) );
    return grapeList;
}

export function GetDistinctGrapeVarieties(allWines) {
    const grapesCacheKey = "grapes_cacheKey";
    if (StorageAvailable()) {
      const cachedGrapes = GetStorage(grapesCacheKey);
      if (cachedGrapes !== null) {
        const _grapes = JSON.parse(cachedGrapes);
        if (_grapes.length) {
          return _grapes;
        }
      } 
    }
    let grapes = [];
    if (allWines.length) {
        for (let w = 0; w < allWines.length - 1; w++) {
            if (allWines[w].Quantity === 0) continue;
            let grapeName = allWines[w].GrapeVariety;
            if (grapes.includes(grapeName)) continue;
            grapes.push(grapeName);    
        }
    }
    if (grapes.length) SetStorage(grapesCacheKey, grapes);
    return grapes;
}

export function GetDistinctLocationList(allWines) {
    const locations = GetDistinctLocations(allWines);
    //console.log(locations)
    const locList = locations.sort().map(loc => ( { value: loc, label: loc } ) );
    return locList;
}

export function GetDistinctLocations(allWines) {
    const locationCacheKey = "locations_cacheKey";
    if (StorageAvailable()) {
      const cachedLocations = GetStorage(locationCacheKey);
      if (cachedLocations !== null) {
        const _loc = JSON.parse(cachedLocations);
        if (_loc.length) {
          return _loc;
        }
      } 
    }
    let locations = [];
    if (allWines.length) {
        for (let w = 0; w < allWines.length - 1; w++) {
            if (allWines[w].Quantity === 0 || allWines[w].Location === null) continue;
            let locationName = allWines[w].Location;
            if (locations.includes(locationName)) continue;
            locations.push(locationName);    
        }
    }
    if (locations.length) SetStorage(locationCacheKey, locations);
    return locations;
}

export function WinesToImpressions(wines, currency = 'SEK') {
    let impressions = [];
    for (let i = 0; i < wines.length; i++) {
        const wine = wines[i];
        const name = wine.Name;
        let year = wine.Year === 1001 || wine.Year === 0 ? "NV" : wine.Year;
        impressions.push({
            'name': `${name} ${year}`, 
            'id': wine.Id,
            'price': wine.Price,
            'brand': wine.Producer,
            'category': wine.WineType,
            'list': 'Search Results',
            'position': i+1
        }); 
    }
    DataLayerPush({
        'ecommerce': {
            'currencyCode': currency,
            'impressions': impressions
        }
    });
    return;
}

export function DataLayerPush(object) {
    setTimeout(() => {
        if (object && window && window.dataLayer) {
            let dataLayer = window.dataLayer || [];
            dataLayer.push(object);
        } else {
            console.log("window or dataLater does not exist, cannot push object.");
        }
    }, 0);
}

export function TryParseInt(str, defaultValue = null) {
    let retValue = defaultValue;
    if (str !== null && str.length && !isNaN(str)) {
        retValue = parseInt(str,10);
    }
    return retValue;
}

export function StorageAvailable(type = DEFAULTS.StorageType) {
    try {
        var storage = window[type],
            x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    }
    catch (e) {
        return e instanceof DOMException && (
            // everything except Firefox
            e.code === 22 ||
            // Firefox
            e.code === 1014 ||
            // test name field too, because code might not be present
            // everything except Firefox
            e.name === 'QuotaExceededError' ||
            // Firefox
            e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
            // acknowledge QuotaExceededError only if there's something already stored
            storage.length !== 0;
    }
}
export function RemoveStorage(name) {
    try {
        localStorage.removeItem(name);
        localStorage.removeItem(name + '_expiresIn');
    } catch (e) {
        console.log('removeStorage: Error removing key [' + name + '] from localStorage: ' + JSON.stringify(e));
        return false;
    }
    return true;
}
/*  getStorage: retrieves a key from localStorage previously set with setStorage().
    params:
        key <string> : localStorage key
    returns:
        <string> : value of localStorage key
        null : in case of expired key or failure
 */
export function GetStorage(key) {
    const now = Date.now();  //epoch time, lets deal only with integer
    // set expiration for storage
    let expiresIn = localStorage.getItem(key + '_expiresIn');
    if (expiresIn === undefined || expiresIn === null) { expiresIn = 0; }

    if (expiresIn < now) {// Expired
        RemoveStorage(key);
        return null;
    } else {
        try {
            const value = localStorage.getItem(key);
            return value;
        } catch (e) {
            console.log('getStorage: Error reading key [' + key + '] from localStorage: ' + JSON.stringify(e));
            return null;
        }
    }
}

/*  setStorage: writes a key into localStorage setting a expire time
    params:
        key <string>     : localStorage key
        value <string>   : localStorage value
        expires <number> : number of days from now to expire the key
    returns:
        <boolean> : telling if operation succeeded
 */
export function SetStorage(key, value, expires) {
    if (expires === undefined || expires === null) {
        expires = DEFAULTS.StorageExpiration;  // default: 1 day
    } else {
        expires = Math.abs(expires); //make sure it's positive
    }
    
    const now = Date.now();  //millisecs since epoch time, lets deal only with integer
    const schedule = now + expires * 1000 * 24 * 60 * 60;
    try {
        localStorage.setItem(key, typeof value === 'string' ? value : JSON.stringify(value));
        localStorage.setItem(key + '_expiresIn', schedule);
    } catch (e) {
        console.log('setStorage: Error setting key [' + key + '] in localStorage: ' + JSON.stringify(e));
        return false;
    }
    return true;
}

export function GetParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[[\]]/g, "\\$&");
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}
