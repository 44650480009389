import React, { Component } from 'react';

export default class Button extends Component {

    handleClick = () => {
        this.props.clickEvent(this.props.defaultValue);
    }

    render() {
        const {id, value, text} = this.props;
        return (
          <button onClick={this.handleClick} id={id} value={value} className="nl-submit" name="submit" type="submit">{text}</button>            
        );
    }
}
