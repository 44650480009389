import React, { Component } from 'react';

class Loader extends Component {
    render() {
        const { visible, message, init } = this.props;
        var displayValue = visible === true ? "flex" : "none";
        var initClass = init === true ? "init" : "";
        return (
            <div className="loader-wrapper" style= {{ display: displayValue }}>
                <div className={initClass + " loader"}>
                    <ul>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                    <div className="wineglass left">
                        <div className="top"></div>
                    </div>
                    <div className="wineglass right">
                        <div className="top"></div>
                    </div>
                    <div className="grapes"></div>
                    <p>{message}</p>
                </div>
            </div>
        )
    };
}
export default Loader;