import * as Utils from './utils.js'; 
let Favorites=[];

export function GetAll() {
    if (Utils.StorageAvailable() && Utils.GetStorage('favorites') !== null) {
        return Utils.GetStorage('favorites').split(',').map(Number);
    }
    return [];
}

export function IsFavorite(id){
    return (GetAll().indexOf(id) === -1) === false;
}

var updateFavorites = function (wines) {
    if (wines.length) {
        Utils.SetStorage('favorites', wines.toString(),365);
    }
    else {
        Utils.RemoveStorage('favorites');
    }
}

export function Add(id) {
    if (IsFavorite(id || id < 1)) {
        return;
     }
    Favorites = GetAll();
    Favorites.push(id);
    updateFavorites(Favorites);
}

export function Remove(id) {
    Favorites = GetAll();
    Favorites.splice(Favorites.indexOf(id),1);
    updateFavorites(Favorites);
}
