import React, { Component } from 'react';
import WineItem from './wine';

import * as Translations from '../utils/translations';
let translations = Translations.GetAll();

class Results extends Component {
    constructor(props) {
        super(props);
        this.goToTop = this.goToTop.bind(this);
    }
    goToTop() {
        window.location.hash = 'search';
    }
    
    render() {
        const { result, showResults } = this.props;
        return (
        <div className="container" id="results" style= {{ display: showResults === true ? "block" : "none" }}>
            <div className="main">
                <table id="wines" className="table table-bordered">
                    <thead>
                        <tr className="mobile-hide">
                            <th>{translations.results.name}</th>
                            <th>{translations.results.country}</th>
                            <th>{translations.results.grape}</th>
                            <th>{translations.results.grade}</th>
                            <th>{translations.results.location}</th>
                            <th style={{minWidth: 100 +'px'}}>{translations.results.price}</th>
                        </tr>
                    </thead>
                    <tbody>
                    {result.map(wine =>
                        <WineItem key={wine.Id} wine={wine} />
                    )}
                    </tbody>
                </table>
                <div><button id="link-to-top" onClick={this.goToTop} className="nl-button" type="button">{translations.results.new_search}</button></div>
            </div>
        </div>
        )
    };
}

export default Results;
