import React, { Component } from 'react';
import { Link } from "react-router-dom";

class Login extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
          handle: props.credentials.handle,
          password: props.credentials.password
        }
    
        this._handleInputChange = this._handleInputChange.bind(this)
        this._handleLogin = this._handleLogin.bind(this)
    }
    
    _handleInputChange(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        var partialState = {}
        partialState[name] = value
        this.setState(partialState)
    }
    
    _handleLogin(event) {
        var tempcredentials = {
            handle: this.state.handle,
            password: this.state.password
        }
        this.props.doLogin(tempcredentials)
        event.preventDefault()
    }

    render() {
        const { visible, message  } = this.props;
        var displayValue = visible === true ? "flex" : "none";
        return (
            <div className="login-wrapper" style={{ display: displayValue }}>
                <div className="login">
                    <div className="login-item">
                        <div className="form form-login">
                            <div className="form-field">
                                <label className="user" htmlFor="ct-handle"><span className="hidden">Username</span></label>
                                <input id="ct-handle" name="handle" type="text" className="form-input" placeholder="Your CellarTracker Handle" onChange={this._handleInputChange} required/>
                            </div>

                            <div className="form-field">
                                <label className="lock" htmlFor="ct-password"><span className="hidden">Password</span></label>
                                <input id="ct-password" name="password" type="password" className="form-input" placeholder="Password" onChange={this._handleInputChange} required/>
                            </div>

                            <div className="form-field">
                                <button onClick={this._handleLogin}>Login</button>
                            </div>
                            <span style={{color: 'red'}}>{message}</span>
                        </div>
                        <p><Link to="/terms" style={{color: 'white'}}>Terms and conditions</Link></p>
                    </div>
                </div>
            </div>
        )
    }
}
export default Login;