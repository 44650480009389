const texts = {
    sv: {
        init_app: 'Initierar app...',
        init_app_done: 'App initierad!',
        search_wines: 'Söker efter vin...',
        search_no_results: 'Hittade inga resultat',
        search_present_results: 'Klart. Visar resultat',
        currency: 'SEK',
        errors: {
            init_error: 'Fel vid inläsning :(',
            no_wines: 'Inga viner?! Köp vin och lägg till i ditt CellarTracker-konto.',
            app_landscape_unsupported: 'Ledsen - landskape-läge stöds ej',
            incorrect_credentials: 'Felaktiga inloggningsuppgifter',
            service_down: 'CellarTracker verkar vara nere',
            error_in_credentials: 'Fel vid inloggning - kontrollera formuläret'
        },
        form: {
            id_like_a_wine_tip: 'Jag vill ha tips på ett vin',
            any_type: 'vilken typ som helst',
            on: ' på ',
            any_grape: 'valfri druva',
            from: ' från ',
            in: ' i ',
            any_origin: 'var som helst',
            any_cellar: 'alla platser',
            cost_span: ' som kostar mellan ',
            and: ' och ',
            or: ' eller ',
            button_search: 'Sök',
            button_surprise: 'Överraska mig',
            button_favorites: 'Favoriter'
        },
        results: {
            new_search: 'Ny sökning',
            name: 'Namn',
            grape: 'Druva',
            grade: 'Betyg',
            location: 'Plats',
            country: 'Land',
            price: 'Pris',
            in_cellar: ' i källaren',
            pending: 'väntande',
            from: ' från ',
            made_of: ' gjort på ',
            and_costs: ' och kostar ',
            pcs: ' st'
        },
        age_types: {
            any_age: 'med valfri ålder',
            up_to_ten_years: 'upp till 10 år',
            ten_years_and_older: '10 år och äldre'
        },
        wine_types: {
            any_type: 'vilken typ som helst',
            red: 'gärna rött',
            white: 'gärna vitt',
            sparkling: 'gärna mousserande'
        }
    },
    en: {
        init_app: 'Initiating app...',
        init_app_done: 'App initiated!',
        search_wines: 'Searching for wine...',
        search_no_results: 'No results',
        search_present_results: 'Done. Presenting results.',
        currency: 'SEK',
        errors:{
            init_error: 'Initialization error - no response from server',
            no_wines: 'No wines?! Buy some add to your CellarTracker account.',
            app_landscape_unsupported: 'Sorry - landscape mode is not supported',
            incorrect_credentials: 'Incorrect credentials - try again',
            service_down: 'CellarTracker seems to be unavailable',
            error_in_credentials: 'Error in credentials - check the login form'
        },
        form: {
            id_like_a_wine_tip: 'I would like a wine',
            on: 'made on ',
            any_grape: ' any grape ',
            from: ' from ',
            in: ' in ',
            any_origin: 'anywhere',
            any_cellar: 'any cellar',
            cost_span: 'price between',
            and: ' and ',
            or: ' or ',
            button_search: 'Search',
            button_surprise: 'Surprise me',
            button_favorites: 'My Favourites'
        },
        results: {
            new_search: 'New search',
            name: 'Name',
            grape: 'Grape',
            grade: 'Grade',
            location: 'Location',
            country: 'Country',
            price: 'Price',
            in_cellar: ' in cellar',
            in_stock: ' in stock',
            pending: 'pending',
            from: ' from ',
            made_of: ' made from ',
            and_costs: ' and costs ',
            pcs: ' pcs'
        },
        age_types: {
            any_age: 'of any age',
            up_to_ten_years: 'up to 10 years',
            ten_years_and_older: '10 years and older'
        },
        wine_types: {
            any_type: 'of any kind',
            red: 'preferably red',
            white: 'preferably white',
            sparkling: 'preferably sparkling'
        }
    }
};


  export function GetAll(){
      return texts.en;
  }