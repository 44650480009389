import React from 'react';
import ReactDOM from 'react-dom';
import './styles/default.css';
import './styles/components.css';
import App from './App';
import { unregister } from './registerServiceWorker';

ReactDOM.render(<App />, document.getElementById('search'));

window.addEventListener('resize', function (e) {
    setViewPortHeight();
}, false);

function setViewPortHeight(){
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}
setViewPortHeight();
unregister();